import { content } from "../assets/content";
import {
  Box,
  TableFooter,
  Typography,
  Popover,
  List,
  ListItemText,
} from "@mui/material";
import { ReactComponent as WhatYoullNeed } from "../assets/images/what-youll-need-subhead.svg";
import { ReactComponent as WifiSystem } from "../assets/images/Wi-Fi-System-Icon.svg";
import { ReactComponent as EthernetCable } from "../assets/images/Ethernet-Cable-Icon.svg";
import { ReactComponent as SetupInSeconds } from "../assets/images/setup-in-seconds-subhead.svg";
import { ReactComponent as WorksWithBoingo } from "../assets/images/Works-With-Boingo-Fiber-Sticker.svg";
import { ReactComponent as NeedHelp } from "../assets/images/need-help-subhead.svg";
import "../assets/stylesheets/welcome.css";

import Header from "../components/Header";
import SetupSteps from "../components/SetupSteps";
import React, { useState, useEffect } from "react";
import WelcomeFooter from "../components/WelcomeFooter";

import { getSelfcareUrl } from "../config/config";
import { Helmet } from "react-helmet";

export default function Welcome() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopOverClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Boingo Fiber - Check Availability</title>
      </Helmet>
      <div className="fiber-welcome-container">
        <div id="welcome-header" sx={{ width: "" }}>
          <Box className="welcome-to">{content.welcomeWelcomeTo}</Box>
          <Header />
        </div>
        <Box className="container welcome">
          <Box className="wrapper welcome">
            <Typography variant="h5" sx={{ fontFamily: "Figtree-Regular" }}>
              {content.welcomeSubhead1}
            </Typography>
            <Typography>{content.welcomeSubhead2}</Typography>
          </Box>
        </Box>
        <Box className="what-youll-need-container">
          <Box className="what-youll-need-wrapper">
            <Box className="welcome-subhead-svg">
              <WhatYoullNeed />
            </Box>
            <Box className="wyn-item wifi">
              <Box>
                <WifiSystem />
              </Box>
              <Box>{content.welcomeWyn1}</Box>
            </Box>
            <Box className="wyn-item ethernet">
              <Box>
                <EthernetCable />
              </Box>
              <Box>{content.welcomeWyn2}</Box>
            </Box>
            <Box className="wyn-item additional">
              <p>
                {content.welcomeWyn3}
                <a
                  href=""
                  aria-describedby={id}
                  variant="contained"
                  onClick={handlePopOverClick}
                >
                  {content.welcomeWyn4}
                </a>
              </p>
              <p>{content.welcomeWyn5}</p>
            </Box>
          </Box>
          <Box className="what-youll-need-devices-wrapper">
            <Box className="device-sticker2">
              <Box>
                <WorksWithBoingo />
              </Box>
            </Box>
            <Box className="wyn-item devices">
              <Box className="device tplink"></Box>
              <Box>{content.welcomeWynTplink}</Box>
            </Box>
            <Box className="wyn-item devices">
              <Box className="device eero"></Box>
              <Box>{content.welcomeWynEero}</Box>
            </Box>
            <Box className="device-sticker1">
              <Box>
                <WorksWithBoingo />
              </Box>
            </Box>
            <Box className="wyn-item devices">
              <Box className="device dlink"></Box>
              <Box>{content.welcomeWynDlink}</Box>
            </Box>
            <Box className="wyn-item devices">
              <Box className="device googleac"></Box>
              <Box>{content.welcomeWynGoogle}</Box>
            </Box>
          </Box>
        </Box>
        <Box className="setup-in-seconds-container">
          <Box className="setup-in-seconds-wrapper">
            <Box className="welcome-subhead-svg">
              <SetupInSeconds />
              <Box>
                <Typography>{content.welcomeSetup}</Typography>
              </Box>
            </Box>
            <SetupSteps />
          </Box>
        </Box>
        <Box className="need-help-wrapper">
          <Box className="welcome-subhead-svg need-help">
            <NeedHelp />
            <Box className="helpSubhead1" sx={{ textAlign: "center" }}>
              <Typography sx={{ marginBottom: 2 }}>
                Sign in to&nbsp;
                <a href={getSelfcareUrl(window.location.host)} target="_blank">
                  My Account
                </a>
              </Typography>
              <Typography>
                {content.welcomeHelpSubhead1}
                <a target="_blank" href="https://support.boingo.com/s/">
                  {content.welcomeHelpSubhead2}
                </a>
                {content.welcomeHelpSubhead3}
              </Typography>
            </Box>
          </Box>
          <Box className="need-help-img"></Box>
          <Box className="helpSubhead2">
            <Typography sx={{ marginBottom: 2 }}>
              Sign in to&nbsp;
              <a href={getSelfcareUrl(window.location.host)} target="_blank">
                My Account
              </a>
            </Typography>
            <Typography>
              {content.welcomeHelpSubhead1}
              <a target="_blank" href="https://support.boingo.com/s/">
                {content.welcomeHelpSubhead2}
              </a>
              {content.welcomeHelpSubhead3}
            </Typography>
          </Box>
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPopover-paper": {
              position: "relative",
              width: "25%", // Default width for larger screens
              "@media (max-width: 767px)": {
                width: "100%", // Width for screens less than 767px
              },
              marginTop: "10px",
              overflow: "visible",
              "&:before": {
                content: '""',
                position: "absolute",
                zIndex: "10",
                top: -16,
                left: 20,
                "@media(max-width: 767px)": {
                  left: 270,
                },
                borderBottom: "8px solid",
                borderBottomColor: "#fff",
                borderLeft: "8px solid transparent",
                borderRight: "8px solid transparent",
                borderTop: "8px solid transparent",
              },
            },
          }}
        >
          <Typography
            className="popover-title"
            sx={{
              p: 2,
              fontFamily: "Figtree-Bold",
              lineHeight: "1.2",
              color: "#000",
            }}
          >
            Make sure your equipment meets the following specs:
          </Typography>
          <List sx={{ listStyleType: "disc", margin: "-10px 0 0px 14px" }}>
            {[
              "\u2022 Supports gigabit wired speeds",
              "\u2022 Wi-Fi 5 (802.11ac) or Wi-Fi 6 (802.11ax)",
              "\u2022 80Mhz channel width",
              "\u2022 Minimum WPA2 Security",
            ].map((text, index) => (
              <ListItemText primary={text} key={index} />
            ))}
          </List>
        </Popover>
        <WelcomeFooter />
      </div>
    </>
  );
}
