import React, { useState, useEffect, useRef } from "react";

import {
  Box,
  Container,
  Select,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Popover,
  List,
  ListItemText,
  useTheme,
  useMediaQuery,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../assets/stylesheets/createAccount.css";

import { Link, NavLink } from "react-router-dom";

import Navbar from "../components/Navbar";
import Breadcrumbs from "../components/Breadcrumbs";
import ProductSection from "../components/ProductSection";
import { content } from "../assets/content";
import {
  formatPhoneNumber,
  validateInput,
  getCookieByName,
  saveToCookie,
  getQueryParam,
} from "../config/utils";

import YoureInImage from "../assets/images/youre-in.svg";
import DownArrowImg from "../assets/images/Down-Arrow.svg";
import setupInSeconds from "../assets/images/setup-in-seconds-savings-subhead.svg";
import worksWithFiber from "../assets/images/Works-With-Boingo-Fiber-Sticker.svg";
import lookForThisSticker from "../assets/images/Look-For-This-Sticker-With-Arrow.svg";
import { Helmet } from "react-helmet";

export default function SignupForm({
  register,
  handleSubmit,
  watch,
  errors,
  addressInfo,
  setAddressInfo,
  finalCustomerInfo,
  setFinalCustomerInfo,
  modemData,
}) {
  const [customerInfo, setCustomerInfo] = useState({
    username: "",
    password: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    address: {
      ...addressInfo,
    },
    modem: {
      ...modemData,
    },
  });

  const [signUpValidationError, setSignUpValidationError] = useState({
    username: "",
    password: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
  });

  useEffect(() => {
    addressInfo &&
      setCustomerInfo({
        ...customerInfo,
        address: { ...addressInfo },
      });
  }, [addressInfo]);

  const productSectionRef = useRef(null);

  // Function to handle click on down-arrow image
  const scrollToProductSection = () => {
    productSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const usernameRegex = /^[A-Za-z0-9_]{6,}$/i;
  const passwordRegex = /^.{6,16}$/;
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const nameRegex = /^[A-Za-z0-9_]+$/i;
  const phoneNumberRegex = /^[0-9-]+$/i;

  const signupFormValidation = (value, regex) => {
    return validateInput(value, regex);
  };

  const handleChange = (event) => {
    if (event.target.id === "phone_number") {
      let formattedPhone = formatPhoneNumber(event);
      setCustomerInfo({
        ...customerInfo,
        phone_number: formattedPhone,
      });
    } else {
      setCustomerInfo({
        ...customerInfo,
        [event.target.id]: event.target.value,
      });
    }
  };

  const handleBlur = (event) => {
    const { id, value } = event.target;
    let error = "";

    switch (id) {
      case "email":
        if (!signupFormValidation(value, emailRegex)) {
          error = "Invalid email format";
        }
        break;
      case "username":
        if (!signupFormValidation(value, usernameRegex)) {
          error = "Invalid username or minimum 6 characters";
        }
        break;
      case "password":
        if (!signupFormValidation(value, passwordRegex)) {
          error = "Password has to be between 6-16 characters";
        }
        break;
      case "first_name":
        if (!signupFormValidation(value, nameRegex)) {
          error = "Invalid first name";
        }
        break;
      case "last_name":
        if (!signupFormValidation(value, nameRegex)) {
          error = "Invalid last name";
        }
        break;
      case "phone_number":
        if (
          !signupFormValidation(value, phoneNumberRegex) ||
          value.length !== 12
        ) {
          error = "Invalid phone number";
        }
        break;
    }

    setSignUpValidationError({ ...signUpValidationError, [id]: error });
  };

  const onSubmitForm = (event) => {
    //get SCC value
    const sccVal = getQueryParam("scc");
    // Check if there are any validation errors
    const hasErrors = Object.values(signUpValidationError).some(
      (error) => error !== ""
    );

    // If there are no errors, update customerInfo object and redirect to the payment page
    if (!hasErrors) {
      //add 1  and space then strip "-" from phone_number
      const updatedPhoneNumber =
        "1 " + customerInfo.phone_number.replace(/-/g, "");

      setFinalCustomerInfo({
        ...customerInfo,
        phone_number: updatedPhoneNumber,
      });

      if (sccVal) {
        setFinalCustomerInfo({
          ...customerInfo,
          scc: sccVal,
        });
      }
      saveToCookie({ ...customerInfo });
      window.location.hash = "/payment";
    } else {
      return;
    }
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    const customerDataCookie = getCookieByName("customerData");
    if (customerDataCookie) {
      const customerData = JSON.parse(customerDataCookie);
      setCustomerInfo((prevState) => ({
        ...prevState,
        first_name: customerData.first_name || "",
        last_name: customerData.last_name || "",
        email: customerData.email || "",
        phone_number: customerData.phone_number || "",
        address: {
          site: customerData.address.site || "",
          street_name: customerData.address.street_name || "",
          street_type: customerData.address.street_type || "",
          house_number: customerData.address.house_number || "",
          unit: customerData.address.unit || "",
        },
        modem: {
          fsan: customerData.modem.fsan || "",
          mac_address: customerData.modem.mac_address || "",
          id: customerData.modem.id || "",
        },
      }));
    }
  }, []);

  const RouterSection = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const open = Boolean(anchorEl);
    const id = open ? "simple-modal" : undefined;

    const handleModalClick = (event) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <Typography className="youre-in-title">
          <strong>Great news. Boingo Fiber is available for your home.</strong>
        </Typography>

        <Modal
          id={id}
          open={open}
          onClose={handleClose}
          className="router-desc-container"
        >
          <Box
            className="router-desc-wrapper"
            sx={{
              transform: "translate(-50%, -50%)",
              "&, & + .MuiModal-root": {
                outline: "none",
              },
              "&, & + .MuiModal-root:focus": {
                outline: "none",
              },
            }}
          >
            <CloseIcon
              style={{
                alignSelf: "flex-end",
                width: isMobile ? "1.1em" : "1.25em",
                height: "auto",
              }}
              onClick={handleClose}
            />
            <Box id="SetupInSeconds" className="setup-header-img">
              <img src={setupInSeconds} />
            </Box>

            <Box>
              <Typography className="router-desc">
                Cable companies charge big monthly fees for their equipment.
                Boingo Fiber is different. Our &#8220;BYO&#8221; plan means you
                use your own equipment, which saves you a bundle.
              </Typography>

              <Typography className="router-desc">
                <span className="router-desc-title">
                  {" "}
                  {content.routerSectionHead}{" "}
                </span>
                {content.routerSectionText}
              </Typography>

              <Typography className="router-desc">
                <span className="router-desc-title">
                  {content.equipmentHead}
                </span>
                You&#8217;re welcome to use any system {isMobile ? <br /> : ""}{" "}
                that meets the specs below. Check out the Exchange for
                compatible routers that work with Boingo Fiber.
              </Typography>

              <Box className="sticker-container">
                <img src={worksWithFiber} className="works-with-fiber-img" />
                <img
                  src={lookForThisSticker}
                  className="look-for-this-sticker-img"
                />
              </Box>

              <Box className="specs-list-wrapper">
                <Typography className="router-desc-title specs-list-title">
                  {content.specsListHead}
                </Typography>

                <List className="specs-list" sx={{ pt: 0, mb: 0 }}>
                  {[
                    "\u2022 Supports gigabit wired speeds",
                    "\u2022 Wi-Fi 5 (802.11ac) or Wi-Fi 6 (802.11ax)",
                    "\u2022 80Mhz channel width",
                    "\u2022 Minimum WPA2 Security",
                  ].map((text, index) => (
                    <ListItemText
                      className="specs-list-item"
                      primary={text}
                      key={index}
                      sx={{
                        "&, .MuiTypography-root": {
                          lineHeight: "1.25",
                          fontSize: isMobile ? "14px" : "16px",
                        },
                      }}
                    />
                  ))}
                </List>
              </Box>
            </Box>
          </Box>
        </Modal>

        <Typography className="youre-in-description">
          With Boingo Fiber you use your own equipment, which saves you a
          bundle. Make sure your equipment meets{" "}
          <a
            href=""
            aria-describedby={id}
            variant="contained"
            onClick={handleModalClick}
          >
            these specs.
          </a>
        </Typography>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Boingo Fiber - Signup</title>
      </Helmet>
      <Box className="signup-youin-container">
        <Box className="yeah-girl-img-wrapper"></Box>
        <img src={YoureInImage} className="youre-in-img" />
        <RouterSection />
      </Box>

      <div ref={productSectionRef}>
        <ProductSection />
      </div>
      <Breadcrumbs />

      <Box className="signup-form-wrapper">
        <Box className="user-info">
          <Typography className="create-account-title">
            Create your account
          </Typography>

          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Box className="info-wrapper">
              <TextField
                className="form-input"
                required
                fullWidth
                type="text"
                margin="dense"
                variant="outlined"
                id="first_name"
                label="First Name"
                value={customerInfo.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!signUpValidationError.first_name}
                helperText={signUpValidationError.first_name}
                color="secondary"
              />

              <TextField
                className="form-input"
                required
                fullWidth
                type="input"
                margin="dense"
                variant="outlined"
                id="last_name"
                label="Last Name"
                value={customerInfo.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!signUpValidationError.last_name}
                helperText={signUpValidationError.last_name}
                color="secondary"
              />

              <TextField
                className="form-input"
                required
                fullWidth
                margin="dense"
                variant="outlined"
                id="email"
                label="Email"
                value={customerInfo.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!signUpValidationError.email}
                helperText={signUpValidationError.email}
                color="secondary"
              />

              <Box sx={{ display: "flex", gap: 0 }}>
                <Select
                  color="secondary"
                  margin="dense"
                  variant="outlined"
                  sx={{
                    marginTop: "8px",
                    height: "56px",
                    width: "120px",
                    borderRadius: "4px 0 0 4px ",
                    backgroundColor: "#fff",
                    opacity: "1",
                    position: "relative",
                    zIndex: "10",
                  }}
                  id="areacode"
                  name="areacode"
                  value={"1"}
                  //onChange={handleSelectChange}
                >
                  <MenuItem value={"1"}>USA(+1)</MenuItem>
                </Select>

                <TextField
                  sx={{
                    width: "100%",
                    marginLeft: "-3px",
                  }}
                  className="form-input"
                  required
                  margin="dense"
                  variant="outlined"
                  id="phone_number"
                  label="Phone Number "
                  value={customerInfo.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!signUpValidationError.phone_number}
                  helperText={signUpValidationError.phone_number}
                  color="secondary"
                  inputProps={{
                    "aria-label": "phone number",
                  }}
                />
              </Box>
            </Box>
            <Button
              sx={{ mb: "100px", width: "100%" }}
              disableElevation
              variant="contained"
              type="submit"
            >
              Continue to checkout
            </Button>
          </form>
        </Box>
      </Box>
      {/* {JSON.stringify(customerInfo)} */}
    </>
  );
}
