import { createCustomerService } from "../services/apiServices";

//generate random transaction id for signup
export const generateGUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

//turn phone number into format of xxx-xxx-xxxx
export const formatPhoneNumber = (event) => {
  var phoneValue = event.target.value.replace(/[^\d]*/g, "");
  // remove non-digits
  phoneValue = phoneValue.replace(
    /(\d{1,3})(\d{1,3})?(\d{1,4})?.*/g,
    "$1 $2 $3"
  );
  phoneValue = phoneValue.trim();
  phoneValue = phoneValue.replace(/\s+/g, "-");
  return phoneValue;
};

//validate input for Milstar CC number, exp date, cvv, and postal
export const validateInput = (value, regex) => {
  return regex.test(value);
};

// save to cookie
export const saveToCookie = (info) => {
  // Serialize the object and store it in a cookie
  const serializedInfo = JSON.stringify(info);
  document.cookie = `customerData=${serializedInfo};path=/`;
};

// get cookieby name
export const getCookieByName = (name) => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
  return null;
};

//delete cookie by name
export const deleteCookieByName = (name) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
};

//get  query parameters before or after # in URL
export const getQueryParam = (param) => {
  const urlParts = window.location.href.split("#");
  let combinedParams = new URLSearchParams();

  // Check if there's a query string before the hash
  if (urlParts[0].includes("?")) {
    const baseQueryString = urlParts[0].split("?")[1];
    const baseParams = new URLSearchParams(baseQueryString);
    for (const [key, value] of baseParams) {
      combinedParams.set(key, value);
    }
  }

  // Check if there's a query string after the hash
  if (urlParts[1] && urlParts[1].includes("?")) {
    const hashQueryString = urlParts[1].split("?")[1];
    const hashParams = new URLSearchParams(hashQueryString);
    for (const [key, value] of hashParams) {
      combinedParams.set(key, value);
    }
  }

  return combinedParams.get(param);
};

export const getPageLoadtime = () => {
  if (window.performance && window.performance.timing) {
    return (
      window.performance.timing.domContentLoadedEventEnd -
      window.performance.timing.navigationStart
    );
  }
  return 0;
};

export const formatMacAddress = (mac) => {
  // remove any delimiters and ensure we only use - delimiters
  const MacOnlyReg = /[^a-gA-G0-9]/g;
  const DashReg = /.{1,2}/g;
  let formattedMacAddress = mac.replace(MacOnlyReg, '');
  const parts = formattedMacAddress.match(DashReg);
  formattedMacAddress = parts !== null ? parts.join('-') : '';
  return formattedMacAddress.length === 17 ? formattedMacAddress : '-1';
};
