import React, { useState } from "react";
import { eventApiWrapperV2Service } from "../services/apiServices";

import { content } from "../assets/content";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box, Container, Typography } from "@mui/material";
import ComingSoonTitle from "../assets/images/coming-soon-title.svg";
import { Helmet } from "react-helmet";

export default function Registration({
  register,
  handleSubmit,
  watch,
  errors,
  addressInfo,
  setAddressInfo,
  finalCustomerInfo,
  setFinalCustomerInfo,
}) {
  const [formErrorPosting, setFormErrorPosting] = useState(false);

  const onSubmitForm = async (data) => {
    eventApiWrapperV2Service(data)
      .then((response) => {
        console.log("Successful posted to eventapi v2: ", response.data);
        window.location.hash = "/completed";
      })
      .catch((error) => {
        console.log("Failed to post to eventApiv2 :", error.response.data);
        setFormErrorPosting(true);
      });
  };
  return (
    <>
      <Helmet>
        <title>Boingo Fiber - Registration</title>
      </Helmet>
      <Header />
      <div className="container">
        <Box
          className="wrapper"
          sx={{ backgroundColor: "#e0edf5", textAlign: "center" }}
        >
          <img src={ComingSoonTitle} width="320px" />
          <Box
            className="body-wrap"
            sx={{
              width: "50%",
              "@media (max-width: 767px)": {
                width: "100%",
              },
              margin: "50px auto",
            }}
          >
            <Typography sx={{ margin: "auto" }}>
              {content.body1}&nbsp;
              {content.body2}
            </Typography>
            <Typography sx={{ margin: "20px auto 50px" }}>
              {content.body3}
            </Typography>

            <form onSubmit={handleSubmit(onSubmitForm)}>
              <input
                {...register("first_name", {
                  required: true,
                  maxLength: 25,
                  pattern: /^[A-Za-z\s,-,.]+$/i,
                })}
                placeholder="First Name"
              />
              {errors.first_name && (
                <p className="input-error">{content.fNameError}</p>
              )}

              <input
                {...register("last_name", {
                  required: true,
                  maxLength: 25,
                  pattern: /^[A-Za-z\s,-,.]+$/i,
                })}
                placeholder="Last Name"
              />
              {errors.last_name && (
                <p className="input-error">{content.lNameError}</p>
              )}

              <input
                {...register("email", {
                  required: true,
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                })}
                placeholder="Email Address"
              />
              {errors.email && (
                <p className="input-error">{content.emailError}</p>
              )}
              <select {...register("countryCode")}>
                <option value="+1">USA (+1)</option>
              </select>
              <input
                className="phoneNum"
                {...register("phone", {
                  required: true,
                  pattern: /^\+?[\d\s-()]{1,20}$/i,
                })}
                placeholder="Phone Number"
              />
              {errors.phone && (
                <p className="input-error">{content.phoneNumberError}</p>
              )}
              <input
                className="address"
                {...register("address", {
                  required: true,
                  pattern: /^[a-zA-Z0-9.#,\*\(\)\s]+$/i,
                })}
                placeholder="Address"
              />
              {errors.address && (
                <p className="input-error">{content.addressError}</p>
              )}
              <select className="unitSelector" {...register("unit")}>
                <option value="">Select a Unit</option>
                <option value="A">Unit A</option>
                <option value="B">Unit B</option>
                <option value="C">Unit C</option>
                <option value="D">Unit D</option>
                <option value="E">Unit E</option>
                <option value="F">Unit F</option>
              </select>
              <input type="submit" value="Get updates" />

              {formErrorPosting && (
                <p className="error">There is an issue with your form</p>
              )}
            </form>
            <span className="note">
              {content.note}&nbsp;
              <a
                href="https://www.boingo.com/legal/boingo-privacy-policy/"
                target="_blank"
              >
                {content.privacy}
              </a>
            </span>
          </Box>
        </Box>
      </div>
      <Footer />
    </>
  );
}
