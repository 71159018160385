// FTTH API URLs
export const enterpriseDomainLocal = "http://localhost:4000/ftth/api";
export const enterpriseDomainDev =
  "https://enterprise.boingogcpdev.com/ftth/api";
export const enterpriseDomainQA = "https://enterprise.boingoqa.com/ftth/api";
export const enterpriseDomainProd = "https://enterprise.boingo.com/ftth/api";

// EM TOOLS URLs
export const toolsDomainLocal = "http://localhost:4001/api/rest";
export const toolsDomainDev =
  "https://enterprise.boingogcpdev.com/api/rest";
export const toolsDomainQA = "https://enterprise.boingoqa.com/api/rest";
export const toolsDomainProd = "https://enterprise.boingo.com/api/rest";

export const selfcareDomainProd = "https://selfcare.boingohotspot.net/login";
export const selfcareDomainDevQaStg =
  "https://selfcare-qa-stg.boingohotspot.net/login";


// API Endpoints
export const FTTH_API_ENDPOINT = process.env.REACT_APP_FTTH_ENDPOINT || "";
export const REACT_APP_TOOLS_API_PATH = process.env.REACT_APP_TOOLS_API_PATH || "";

// Braintree Token
const BRAINTREE_AUTH_TOKEN_SANDBOX = "sandbox_6m863gmh_gwtp8qvdpq6ftkxf";
const BRAINTREE_AUTH_TOKEN_PROD = "production_q7fzphz5_tdcvffwq2bmzqmxj";

// return domain
export const getEnterpriseUrl = (host) => {
  if (host.includes(".boingo.com")) {
    return enterpriseDomainProd;
  } else if (host.includes("localhost")) {
    return enterpriseDomainLocal;
  } else if (host.includes(".boingogcpdev.com")) {
    return enterpriseDomainDev;
  } else if (host.includes(".boingoqa.com")) {
    return enterpriseDomainQA;
  }
};

export const getBraintreeAuthTokenFromDomain = (host) => {
  return host.includes(".boingo.com")
    ? BRAINTREE_AUTH_TOKEN_PROD
    : BRAINTREE_AUTH_TOKEN_SANDBOX;
};

// return selfcare domain
export const getSelfcareUrl = (host) => {
  return host.includes(".boingo.com")
  ? selfcareDomainProd
  : selfcareDomainDevQaStg;
};

// return tools domain
export const getToolsUrl = (host) => {
  if (host.includes(".boingo.com")) {
    return toolsDomainProd;
  } else if (host.includes("localhost")) {
    return toolsDomainLocal;
  } else if (host.includes(".boingogcpdev.com")) {
    return toolsDomainDev;
  } else if (host.includes(".boingoqa.com")) {
    return toolsDomainQA;
  }
};
